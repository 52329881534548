import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {

      let sChart = this.canvasChart
      .append('g')
      .attr('class', 'CHARTS')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.draw_Grid(sChart) // -----> draw_Elements.js
      
      // Call Chart
      this.chart.start.filter(f=> f.cType == 'BAR').forEach((d,i)=> {
        this.draw_BarChart(sChart, this.rawData, d)
      })

      let chartData = null
      this.chart.start.filter(f=> f.cType == 'LINE').forEach((d,i)=> {
        if (d.lType == 'ACT') chartData = this.rawData.filter(f=> f.CDATE <= this.timeline.cutoff)
        else chartData = this.rawData
        this.draw_LineChart(sChart, chartData, d)
      })

    },


    draw_Grid(selection) {
      let grid = this.chart.height / 5

      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', grid * i).attr('y2', grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(((this.secoundary.max/1000) * (i*.2)).toFixed(0) + 'k')
      }
    },


    draw_BarChart(selection, chtData, st_) {

      let barWidth = 2

      let barChart = selection
      .append('g')
      .attr('transform', `translate(0.5,0)`)
      
      chtData.forEach((d,i)=> {
        let value = d[st_.code]
        if(value < 0) value = 0

        barChart
        .append('rect')
        .attr('id', `BAR_${st_.lType}_${i}`)
        .attr('x', (st_.lType == 'PLAN') ? this.timeline.scale(d.mDate) - barWidth - .25 : this.timeline.scale(d.mDate) + .25)
        .attr('y', this.chart.height - this.primary.axis(value))
        .attr('width', barWidth).attr('height', this.primary.axis(value))
        .attr('fill', st_.sColor).attr('opacity', st_.opacity)
      })
    },


    draw_LineChart(selection, chtData, st_) {

      // console.log(chtData)

      let dev = null
      let lastOne = chtData.find(f=> f.CDATE == this.timeline.cutoff)
      if(lastOne) {
        dev = lastOne[this.chart.start[1].code]-lastOne[this.chart.start[0].code]
      }

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.secoundary.axis(d[st_.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('id', `LINE_${st_.lType}`)
      .attr('d', lineFunc(chtData))
      .attr('stroke', (st_.lType == 'ACT' && dev < 0) ? '#F35E90': st_.sColor)
      .attr('stroke-dasharray', (st_.lType == 'PLAN') ? (3,3) : (0,0))
      .attr('stroke-width', st_.sWidth).style('fill', 'none')

      // draw the final cumulative value
      if(st_.lType == 'ACT') {
        if(!lastOne) return
        lineChart
        .append('text')
        .attr('id', `LAST_VALUE`)
        .attr('x', this.timeline.scale(new Date(lastOne.CDATE)))
        .attr('y', this.secoundary.axis(lastOne[st_.code]))
        .style('font-size', 10)
        .style('fill', (dev < 0) ? '#F35E90' : '#44A9DF')
        .attr('alignment-baseline', 'middle')
        .text(lastOne[st_.code])
      }
    },


    draw_ChartAxis(selection) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-28}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Weekly Shop Dwg. Qty')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${this.chart.width + 28}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Cumulative Shop Dwg. Qty')
    },


    draw_Legends() {

      let item = this.canvasForms
      .append('g')
      .attr('id', `LEGEND_GROUP`)
      .attr('transform', `translate(${this.chart.x}, ${this.chart.y - 8})`)

      let x_ = 0
      this.chart.start.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 8).attr('height', (i<2) ? 1 : 3).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNodeElValue(`#ITEM_${i}`, 'width') + 20
      })
    },

  }
}