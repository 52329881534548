import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {
      
      // Canvas Group
      this.canvasGroup = this.canvasForms
      .append('g')
      .attr('transform', `translate(0, 0)`)



        this.draw_ChartCanvas(this.canvasChart) // -----> draw_ChartCanvas.js
        


        // Set primary & secoundary Axis
        let chtData = this.rawData.filter(f=> f.PHASE == 'DETL' && f.CONT == 'Exmar')

        let maxValue = d3.max([
          d3.max(chtData, c => c.P_WK), 
          d3.max(chtData, c => c.A_WK),
        ])
        this.primary.max = this.getMaxBound_v2(maxValue)
        this.primary.axis = d3.scaleLinear()
        .domain([0, this.primary.max])
        .range([0, this.chart.height])

        this.secoundary.max = this.getMaxBound_v2(d3.max(chtData, c => c.P_CUM))
        this.secoundary.axis = d3.scaleLinear()
        .domain([0, this.secoundary.max])
        .range([this.chart.height, 0])
        

        
        this.draw_Timeline() // -----> draw_Timeline.js

        this.chart_Controller(chtData) // -----> draw_Chart.js


      // console.log(this.primary)
    },

  }
}
           