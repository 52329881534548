import * as d3 from 'd3'

export default {
  methods: {

    link_Summary(data_, st_) {
      let pa = data_.code.substring(0,1)
      let sf = data_.code.substring(1,2)
      let col = []

      if (pa == 'P' || pa == 'O') {
        if (sf == 'S') {
          col[0] = 'P_SD'
          if (pa == 'O') col[1] = 'A_SD'
        } else if (sf == 'F') {
          col[0] = 'P_FD'
          if (pa == 'O') col[1] = 'A_FD'
        }
      } else if (pa == 'A') {
        if (sf == 'S') {
          col[0] = 'A_SD'
        } else if (sf == 'F') {
          col[0] = 'A_FD'
        }
      }

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''
      filterString_ += `[J_AREA] = '${st_.id}'`
      if (data_.code !== 'TOTAL') {
        filterString_ += `AND [${col[0]}] <= '${this.timeline.cutoff}'`
        if (pa == 'O') filterString_ += ` AND [${col[1]}] IS NULL`
      }

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    }, 
    
  }
}