
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({

    rawData:      [],

    canvasForms:  null,
    canvasGroup:  null,
    canvasChart:  null,

    chart: {
      x: 70, 
      y: 100,
      width: 1250,
      height: 300,
      grid: 300/5,
      sch: 'start',
      
      style: [
        {name: 'Plan Cuml.',   code: 'P_CUM', cType: 'LINE', lType: 'PLAN', sWidth: 0.5, sColor: '#A7A7A7', opacity: 1 },
        {name: 'Actual Cuml.', code: 'A_CUM', cType: 'LINE', lType: 'ACT',  sWidth: 1.5, sColor: '#44A9DF', opacity: 1 },
        {name: 'Plan wk.',     code: 'P_WK',  cType: 'BAR',  lType: 'PLAN', sWidth: 0.5, sColor: '#bcbcbc', opacity: .3},
        {name: 'Actual wk.',   code: 'A_WK',  cType: 'BAR',  lType: 'ACT',  sWidth: 0.5, sColor: '#83D2F5', opacity: .9},
      ],
    },

    timeline:     {},

    icon:         icon.arrow,
    scaleX: null,
    primary:      {},
    secoundary:   {},














    
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}