import * as d3 from 'd3'

export default {
  methods: {

    draw_Detail(svg) {
      let header = svg
      .append('g')
      .attr('id', `header_${this.localId}`)

      let dataTable = svg
      .append('g')
      .attr('id', `dataTable_${this.localId}`)

      this.draw_Header(header)

      this.draw_DataTable(dataTable)
    },
    
    draw_Header(header) {
      let dt = this.chartInfo.detail

      this.draw_Line(header, 'horizontal', 0, this.chartInfo.width, 0, 0, '#333', 0.2, 1)
      this.draw_Line(header, 'horizontal', 0, this.chartInfo.width, 46, 46, '#333', 0.2, 1)

      this.draw_Text(header, dt.groupWidth - 7, 23, 12, 'end', 'middle', '#333', dt.groupName)

      this.twColWidth = (this.chartInfo.width - dt.groupWidth - (dt.barWidth + 4)) * 0.54 / 5
      this.cumColWidth = (this.chartInfo.width - dt.groupWidth - (dt.barWidth + 4)) * 0.46 / 4
      
      this.draw_Text(header, dt.groupWidth + (this.chartInfo.width - dt.groupWidth - dt.barWidth) * 0.54 / 5 * 2.5, 8, 10, 'middle', 'middle', '#333', 'This week')
      this.draw_Text(header, dt.groupWidth + this.twColWidth, 23, 10, 'middle', 'middle', '#333','Plan')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 2.5), 23, 10, 'middle', 'middle', '#333','Actual')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 4), 23, 10, 'middle', 'middle', '#333','Delta')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 0.5), 38, 9, 'middle', 'middle', '#757575','Early')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 1.5), 38, 9, 'middle', 'middle', '#757575','Late')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 2.5), 38, 9, 'middle', 'middle', '#757575','(%)')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 3.5), 38, 9, 'middle', 'middle', '#757575','Early')
      this.draw_Text(header, dt.groupWidth + (this.twColWidth * 4.5), 38, 9, 'middle', 'middle', '#757575','Late')

      this.draw_Line(header, 'vertical', dt.groupWidth + this.twColWidth * 5, dt.groupWidth + this.twColWidth * 5, 0, 46, '#707070', 0.2, 1)

      let cumX = dt.groupWidth + this.twColWidth * 5

      this.draw_Text(header, cumX + (this.chartInfo.width - cumX) / 2, 8, 10, 'middle', 'middle', '#333','Cumulative Progress')
      this.draw_Text(header, cumX + this.cumColWidth, 23, 10, 'middle', 'middle', '#333','Plan')
      this.draw_Text(header, cumX + this.cumColWidth * 3, 23, 10, 'middle', 'middle', '#333','Delta')
      this.draw_Text(header, this.chartInfo.width - dt.barWidth / 2, 23, 10, 'middle', 'middle', '#333','Progress(%)')
      this.draw_Text(header, cumX + this.cumColWidth * 0.5, 38, 9, 'middle', 'middle', '#757575','Early')
      this.draw_Text(header, cumX + this.cumColWidth * 1.5, 38, 9, 'middle', 'middle', '#757575','Late')
      this.draw_Text(header, cumX + this.cumColWidth * 2.5, 38, 9, 'middle', 'middle', '#757575','Early')
      this.draw_Text(header, cumX + this.cumColWidth * 3.5, 38, 9, 'middle', 'middle', '#757575','Late')
      this.draw_Text(header, this.chartInfo.width - dt.barWidth, 40, 7, 'start', 'middle', '#757575','0')
      this.draw_Text(header, this.chartInfo.width - dt.barWidth / 2, 40, 7, 'middle', 'middle', '#757575','50')
      this.draw_Text(header, this.chartInfo.width, 40, 7, 'end', 'middle', '#757575','100')

      this.draw_Line(header, 'horizontal', dt.groupWidth, this.chartInfo.width, 15, 15, '#707070', 0.2, 1)
      this.draw_Line(header, 'horizontal', dt.groupWidth, dt.groupWidth + (this.twColWidth * 2), 30, 30, '#707070', 0.2, 1)
      this.draw_Line(header, 'horizontal', dt.groupWidth + (this.twColWidth * 3), cumX, 30, 30, '#707070', 0.2, 1)
      this.draw_Line(header, 'horizontal', cumX, cumX + (this.cumColWidth * 4), 30, 30, '#707070', 0.2, 1)

      this.draw_Line(header, 'vertical', dt.groupWidth, dt.groupWidth, 0, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', dt.groupWidth + this.twColWidth, dt.groupWidth + this.twColWidth, 30, 46, '#707070', 0.1, 1)
      this.draw_Line(header, 'vertical', dt.groupWidth + this.twColWidth * 2, dt.groupWidth + this.twColWidth * 2, 15, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', dt.groupWidth + this.twColWidth * 3, dt.groupWidth + this.twColWidth * 3, 15, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', dt.groupWidth + this.twColWidth * 4, dt.groupWidth + this.twColWidth * 4, 30, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', cumX + this.cumColWidth, cumX + this.cumColWidth, 30, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', cumX + this.cumColWidth * 2, cumX + this.cumColWidth * 2, 15, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', cumX + this.cumColWidth * 3, cumX + this.cumColWidth * 3, 30, 46, '#707070', 0.2, 1)
      this.draw_Line(header, 'vertical', cumX + this.cumColWidth * 4, cumX + this.cumColWidth * 4, 15, 46, '#707070', 0.2, 1)
    },

    draw_DataTable(svg) {
      let dt = this.chartInfo.detail
      let rowHeight = this.chartInfo.detail.rowHeight
      let y = 46 + rowHeight / 2

      this.rawData.filter(f => f[dt.groupCol] !== 'Overall').forEach((d,i) => {
        this.draw_Text(svg, dt.groupWidth - 7, y, dt.bar.tSize, 'end', 'central', '#333', d[dt.groupCol])
        this.draw_Text(svg, dt.groupWidth + this.twColWidth - 5, y, dt.bar.tSize, 'end', 'central', '#757575', d.PE_WK)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 2 - 5, y, dt.bar.tSize, 'end', 'central', '#757575', d.PL_WK)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 3 - 5, y, dt.bar.tSize, 'end', 'central', '#333', d.AC_WK)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 4 - 5, y, dt.bar.tSize, 'end', 'central', d.DE_WK > 0 ? '#44A9DF' : d.DE_WK < 0 ? '#EC407A' : '#333', d.DE_WK)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 5 - 5, y, dt.bar.tSize, 'end', 'central', d.DL_WK > 0 ? '#44A9DF' : d.DL_WK < 0 ? '#EC407A' : '#333', d.DL_WK)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 5 + this.cumColWidth - 5, y, dt.bar.tSize, 'end', 'central', '#757575', d.PE_CUM)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 5 + this.cumColWidth * 2 - 5, y, dt.bar.tSize, 'end', 'central', '#757575', d.PL_CUM)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 5 + this.cumColWidth * 3 - 5, y, dt.bar.tSize, 'end', 'central', d.DE_CUM > 0 ? '#44A9DF' : d.DE_CUM < 0 ? '#EC407A' : '#333', d.DE_CUM)
        this.draw_Text(svg, dt.groupWidth + this.twColWidth * 5 + this.cumColWidth * 4 - 5, y, dt.bar.tSize, 'end', 'central', d.DL_CUM > 0 ? '#44A9DF' : d.DL_CUM < 0 ? '#EC407A' : '#333', d.DL_CUM)

        this.draw_ProgressBar(svg, this.chartInfo.width - this.chartInfo.detail.barWidth, y, this.chartInfo.detail.barWidth, dt.bar.tSize + 1, dt.bar.bgColor, dt.bar.color, dt.bar.bgOpacity, dt.bar.opacity, d)

        y += rowHeight
      })

      y -= rowHeight / 2

      this.draw_Line(svg, 'horizontal', 0, this.chartInfo.width, y, y, '#333', 0.2, 1)

      d3.selectAll(`.vertical_${this.localId}`).attr('y2', y)
    },

    draw_Text(svg, x, y, fs, ta, ab, f, t) {
      svg
      .append('text')
      .attr('x', x)
      .attr('y', y)
      .attr('font-size', fs)
      .attr('text-anchor', ta)
      .attr('alignment-baseline', ab)
      .attr('fill', f)
      .text(t)
    },

    draw_Line(svg, cl, x1, x2, y1, y2, s, sw, o) {
      svg
      .append('line')
      .attr('class', `${cl}_${this.localId}`)
      .attr('x1', x1).attr('x2', x2)
      .attr('y1', y1).attr('y2', y2)
      .attr('stroke', s)
      .attr('stroke-width', sw)
      .attr('opacity', o)
    },

    draw_ProgressBar(svg, x, y, w, h, f1, f2, o1, o2, d) {
      let dt = this.chartInfo.detail

      svg
      .append('rect')
      .attr('x', x).attr('y', y - (dt.bar.tSize + 1) / 2)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', f1)
      .attr('opacity', o1)

      svg
      .append('rect')
      .attr('x', x).attr('y', y - (dt.bar.tSize + 1) / 2)
      .attr('width', this.scale.dt(d.AC_CUM))
      .attr('height', h)
      .attr('fill', f2)
      .attr('opacity', o2)

      this.draw_Text(svg, d.AC_CUM > 50 ? x + this.scale.dt(d.AC_CUM) - 3 : x + this.scale.dt(d.AC_CUM) + 3, y, dt.bar.tSize, d.AC_CUM > 50 ? 'end' : 'start', 'central', dt.bar.tColor, `${d.AC_CUM}%`)
    }
  }
}