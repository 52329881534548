import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      this.canvasChart = this.canvasForms
      .append('g')
      .attr('transform', `translate(${this.chart.x +.5}, ${this.Canvas.CanvasHeight - this.chart.height - 50})`)
      .style('font-family', 'roboto')

       // Overall Summary
      this.func_box_Summary(
        this.canvasChart,
        this.summaryData.find(f=> f.AREA == 'OVERALL' && f.DISC == 'OVERALL'), 
        {id: 'OVERALL', x: 396.5, y: -40}
      )

      // Table by Area
      this.draw_Table(
        this.summaryData.filter(f=> f.DISC == 'OVERALL'), 
        {x:860, y: 93, group: 'AREA'}
      )

      // Table by Discipline
      this.draw_Table(
        this.summaryData.filter(f=> f.AREA  == 'OVERALL'), 
        {x:860, y: 250, group: 'DISC'}
      )

      this.draw_Timeline()
      this.draw_ChartAxis(this.canvasChart)

      this.chart_Controller()
      this.draw_Legends()

      this.draw_RadioButton()
    },


    draw_RadioButton() {

      let button = this.canvasForms
      .append('g')
      .attr('transform', `translate(${this.chart.x}, ${this.chart.y - 50})`)

      button
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 12).attr('fill', '#000')
      .text('Histogram Chart for Overall')

      this.schedule.forEach((d,i)=> {

        let item = button
        .append('g')
        .attr('id', `SCH_${d.name}`)
        .attr('transform', `translate(${i*50+7}, 15)`)
        .attr('cursor', 'pointer')
        .on('mouseover', ()=> {
          if(this.chart.sch == d.name) return
          d3.select(`#SCH_${d.name}`).attr('opacity', .5)
        })
        .on('mouseout', ()=> {
          d3.select(`#SCH_${d.name}`).attr('opacity', 1)
        })
        .on('click', ()=> {
          d3.select(`#SCH_CHK_${this.chart.sch}`).attr('fill', '#fff')
          d3.select(`#SCH_TEXT_${this.chart.sch}`).attr('fill', '#757575')

          this.chart.sch = d.name
          d3.select(`#SCH_CHK_${d.name}`).attr('fill', '#F35E90')
          d3.select(`#SCH_TEXT_${d.name}`).attr('fill', '#333')

          this.change_Chart(d.name) //-----> below
        })

        item
        .append('circle')
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 5).attr('stroke', '#F7BACF').attr('stroke-width', 1).attr('fill', '#fff')

        item
        .append('circle')
        .attr('id', `SCH_CHK_${d.name}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 2.5).attr('stroke', '#F35E90').attr('stroke-width', 0)
        .attr('fill', d.check == true ? '#F35E90' : '#fff')

        item
        .append('text')
        .attr('id', `SCH_TEXT_${d.name}`)
        .attr('transform', `translate(11, 3)`)
        .attr('font-size', 10).attr('fill', '#333')
        .attr('font-weight', d.check == true ? 500 : 400)
        .text(d.name)

      })
    },
    

    change_Chart(sch_) {

      let changeSch = this.chart[sch_.toLowerCase()]

      // Change the Box of Summary
      if(sch_ == 'Start') {
        d3.selectAll(`.Issued`).transition().duration(300).attr('opacity', 0.2)
        d3.selectAll(`.Start`).transition().duration(500).attr('opacity', 1)
      } else {
        d3.selectAll(`.Start`).transition().duration(300).attr('opacity', 0.2)
        d3.selectAll(`.Issued`).transition().duration(500).attr('opacity', 1)
      }

      // Change Bar Chart
      changeSch.filter(f=> f.cType == 'BAR').forEach(st=> {
        
        this.rawData.forEach((d,i)=> {
          let value = d[st.code]
          if(value < 0) value = 0

          d3.select(`#BAR_${st.lType}_${i}`).transition().duration(300)
          .attr('y', this.chart.height - this.primary.axis(value))
          .attr('height', this.primary.axis(value))
        })
      })


      // Change the Line Chart
      let chartData = null
      changeSch.filter(f=> f.cType == 'LINE').forEach((st,i)=> {

        if (st.lType == 'ACT') chartData = this.rawData.filter(f=> f.CDATE <= this.timeline.cutoff)
        else chartData = this.rawData
        
        let dev = null
        let lastOne = chartData.find(f=> f.CDATE == this.timeline.cutoff)
        if(lastOne) {
          dev = lastOne[changeSch[1].code]-lastOne[changeSch[0].code]
        }

        let lineFunc = d3.line()
        .x(d => this.timeline.scale(new Date(d.mDate)))
        .y(d => this.secoundary.axis(d[st.code]))

        d3.select(`#LINE_${st.lType}`).transition().duration(300)
        .attr('d', lineFunc(chartData))
        .attr('stroke', (st.lType == 'ACT' && dev < 0) ? '#F35E90': st.sColor)

        // Change the final Value
        if(st.lType == 'ACT') {
          d3.select(`#LAST_VALUE`).transition().duration(300)
          .attr('y', this.secoundary.axis(lastOne[st.code]))
          .text(lastOne[st.code])
        }
      })

      

    },
  }
}
           