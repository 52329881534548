import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller(chtData) {

      let sChart = this.canvasChart
      .append('g')
      .attr('class', 'CHARTS')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.draw_Grid(sChart) // -----> draw_Elements.js
      
      // Call Chart
      this.chart.style.filter(f=> f.cType == 'BAR').forEach((d,i)=> {
        this.draw_BarChart(sChart, chtData, d)
      })

      this.chart.style.filter(f=> f.cType == 'LINE').forEach((d,i)=> {
        if (d.lType == 'ACT') chtData = chtData.filter(f=> f.CDATE <= this.timeline.cutoff)
        this.draw_LineChart(sChart, chtData, d)
      })

    },


    draw_Grid(selection) {

      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', this.chart.grid * i).attr('y2', this.chart.grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (this.chart.grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (this.chart.grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((this.secoundary.max * (i*.2)).toFixed(0))
      }
    },


    draw_BarChart(selection, data, st) {

      let barWidth = 1.5

      let barChart = selection
      .append('g')
      .attr('transform', `translate(0.5,0)`)

      data.forEach((d,i)=> {

        barChart
        .append('rect')
        .attr('id', `BAR_${st.lType}_${i}`)
        .attr('x', (st.lType == 'PLAN') ? this.timeline.scale(d.mDate) - barWidth : this.timeline.scale(d.mDate))
        .attr('y', this.chart.height-this.primary.axis(d[st.code]))
        .attr('width', barWidth).attr('height', this.primary.axis(d[st.code]))
        .attr('fill', st.sColor).attr('opacity', st.opacity)

        // draw the value of Bar chart
        // if (st.lType =='PLAN' || d[st.code] == 0) return
        // barChart
        // .append('text')
        // .attr('x', this.timeline.scale(d.mDate) + (barWidth/2))
        // .attr('y', this.chart.height-this.primary[sn].axis(d[st.code]) -2)
        // .attr('text-anchor', 'middle').attr('font-size', 8).attr('fill', '#83D2F5')
        // .text(d[st.code])
      })
    },


    draw_LineChart(selection, data, st) {
      let dev = null
      let lastOne = data.find(f=> f.CDATE == this.timeline.cutoff)
      if(lastOne) {
        dev = lastOne[this.chart.style[1].code]-lastOne[this.chart.style[0].code]
      }

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.secoundary.axis(d[st.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('id', `LINE_${st.lType}`)
      .attr('d', lineFunc(data))
      .style('stroke', (st.lType == 'ACT' && dev < 0) ? '#F35E90': st.sColor)
      .style('stroke-dasharray', (st.lType == 'PLAN') ? (3,3) : (0,0))
      .style('stroke-width', st.sWidth).style('fill', 'none')

      // draw the final cumulative value
      if(st.lType == 'ACT') {
        if(!lastOne) return
        lineChart
        .append('text')
        .attr('id', `LAST_${st.lType}`)
        .attr('x', this.timeline.scale(new Date(lastOne.CDATE)))
        .attr('y', this.secoundary.axis(lastOne[st.code]))
        .style('font-size', 10)
        .style('fill', (dev < 0) ? '#F35E90' : '#44A9DF')
        .attr('alignment-baseline', 'middle')
        .text(lastOne[st.code])
      }
    },


  }
}