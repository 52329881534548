import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').attr('cursor', 'default')

    
      this.func_barChart_Summary(
        this.canvasForms,
        this.summaryData.find(f=> f.AREA  == 'OVERALL' && f.DISC == 'OVERALL'),
        {
          id: 'overall',
          x: 30, y: 25,
          title:{
            y: 0,
            main: 'Shop Drawing Progress', mainSize: 12,
            sub: 'Cumulative',             subSize: 9,
          },
          bar: {length: 300, height: 18, r: 2, tSize: 12, textY: 38},
          dev: {y: 11, pad: 17, scale: .75}
        }
      ) //-----> func > barChart_Summary.js



    },

  }
}
           