import * as d3 from 'd3'

export default {
  methods: {

    func_box_Summary(selection, data, st) {

      let table = [
        {title: 'Total',  name: '',       code: 'TOTAL',  width: 40, margin: 8,  tColor:'#757575', sColor: '#757575', sSize: 1 },
        {title: 'Plan',   name: 'Start',  code: 'PS_CUM', width: 28, margin: 4,  tColor:'#757575', sColor: '#bcbcbc', sSize: .5},
        {title: '',       name: 'Issued', code: 'PF_CUM', width: 28, margin: 8,  tColor:'#757575', sColor: '#bcbcbc', sSize: .5},
        {title: 'Actual', name: 'Start',  code: 'AS_CUM', width: 28, margin: 4,  tColor:'#757575', sColor: '#bcbcbc', sSize: .5},
        {title: '',       name: 'Issued', code: 'AF_CUM', width: 28, margin: 12, tColor:'#757575', sColor: '#bcbcbc', sSize: .5},
        {title: 'Delta',  name: 'Start',  code: 'DS',     width: 28, margin: 4,  tColor:'#757575', sColor: '#E0E0DF', sSize: .5},
        {title: '',       name: 'Issued', code: 'DF',     width: 28, margin: 12, tColor:'#757575', sColor: '#E0E0DF', sSize: .5},
        {title: 'Overdue',name: 'Start',  code: 'OS',     width: 28, margin: 4,  tColor:'#F35E90', sColor: '#F7BACF', sSize: 1 },
        {title: '',       name: 'Issued', code: 'OF',     width: 28, margin: 8,  tColor:'#757575', sColor: '#F7BACF', sSize: 1 }, 
      ]

      let x = 0
      table.forEach(d=> {
        d.cx = x + d.width / 2
        d.x = x
        x = x + d.width + d.margin
      })
      

      let summary = selection
      .append('g')
      .attr('transform', `translate(${st.x +.5}, ${st.y +.5})`)


      table.forEach((d,i)=> {
        // Title
        if (d.title !== '') {
          summary
          .append('text')
          .attr('x', (i>0) ? d.cx + (d.width+d.margin)/2 : d.cx)
          .attr('y', 0)
          .attr('font-size', 10).attr('fill', d.tColor).attr('text-anchor', 'middle')
          .text(d.title)

          if (i>0) {
            summary
            .append('path')
            .attr('d', `M${d.cx},7 v-3 H${table[i+1].cx} v3`)
            .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('fill', 'none')
          }
        }
        
        // table Box
        summary
        .append('rect')
        .attr('class', `${d.name}`)
        .attr('x', d.x).attr('y', 7)
        .attr('width', d.width).attr('height', 14).attr('fill', '#fff')
        .attr('stroke', d.sColor).attr('stroke-width', d.sSize)
        .attr('opacity', (d.name == 'Start' || d.name == '') ? 1 : 0.2)

        // table
        summary
        .append('text')
        .attr('id', `SUMMARY_${st.id}_${i}`)
        .attr('x', d.cx).attr('y', 17.5)
        .attr('font-size', 10)
        .style('cursor', (d.code == 'DS' || d.code == 'DF') ? 'default' : 'pointer')
        .attr('fill', () => {
          if (d.code == 'DS' || d.code == 'DF') return this.setDeltaColor(data[d.code])
          else if(d.code == 'OS' || d.code == 'OF') return '#F35E90'
          else return '#757575'
        })
        .attr('text-anchor', 'middle')
        .text(data[d.code])
        .on('mouseover', ()=> {
          if (d.code == 'DS' || d.code == 'DF') return
          d3.select(`#SUMMARY_${st.id}_${i}`).transition().duration(200)
          .attr('x', d.cx).attr('font-size', 11)
        })
        .on('mouseout', ()=> {
          if (d.code == 'DS' || d.code == 'DF') return
          d3.select(`#SUMMARY_${st.id}_${i}`).transition().duration(200)
          .attr('x', d.cx).attr('font-size', 10)
        })
        .on('click', ()=> { 
          if (d.code == 'DS' || d.code == 'DF') return
          this.link_Summary(d, st)
        })

        // Stsrt or Fisnish
        summary
        .append('text')
        .attr('x', d.cx).attr('y', 30)
        .attr('font-size', 8.5).attr('fill', '#bcbcbc').attr('text-anchor', 'middle')
        .text(d.name)
      })

    },



  }
}