import * as d3 from 'd3'

export default {
  methods: {
    

    link_Table(data_, st_) {
      console.log(
        'AREA:', data_.AREA, ' / ',
        'DISC:', data_.DISC,  ' / ',
        'COLUMN:', st_.code
      )
    }, 


    link_Summary(data_) {
      console.log(
        'COLUMN:', data_.code
      )
    }, 

    
  }
}