import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import Data from '../../../../../includes/primitives/Color_Data'
import arrow from '../../../../../includes/primitives/icons'

export default {
  methods: {

    draw_Elements() {
      let tube = this.tube
      let columns = this.columns

      let TubeChart = this.svg
      .append('g')
      .attr('transform', `translate(${20}, ${20})`)
      .style('font-family', 'roboto')

      this.set_ColorDefs(TubeChart)

      let TubeScale = d3.scaleLinear()
      .domain([0, 100])
      .rangeRound([tube.height, 0])

      this.tube.scale = TubeScale

      Defs.setDefs_Reflections(TubeChart, this.localId)
      Defs.setDefs_Gradients(TubeChart, 0, 'LinearA2', tube.color, [0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(TubeChart, 1, 'LinearA9', tube.color, [0, 0.5, 1, 0.5], this.localId)

      // --------------------------------------------------------------------------------------------------------------------------------------
      // Draw Tube Chart
      // Start from here
      let chartWrapper = TubeChart
      .append('g')
      .attr('transform', `translate(${tube.x + (tube.tickness/2)},${tube.y + (tube.tickness/4)})`)

      // ---------------------------  Reflected Effect
      chartWrapper // Reflected Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height + tube.base.height})`)
      .attr('width', tube.tickness).attr('height', tube.base.height).attr('opacity', '0.75').attr('fill', '#BDBCBC')

      chartWrapper // Transparent Gradation Area
      .append('rect')
      .attr('transform', `translate(${-tube.tickness / 2},${tube.height + tube.base.height})`)
      .attr('width', tube.tickness * 2.5).attr('height', tube.base.height + 2).attr('fill', `url(#${this.localId}__Color_Reflection)`)

      chartWrapper // Shadow
      .append('ellipse')
      .attr('cx', tube.tickness / 4).attr('cy', tube.height + tube.base.height)
      .attr('rx', (tube.tickness * 1.5) / 2).attr('ry', (tube.tickness * 1.5) / 3 / 2).attr('fill', `url(#${this.localId}_Shadow)`)


      // --------------------------- d.base.height Cylinder
      chartWrapper // Series Bottom Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height})`)
      .attr('width', tube.tickness).attr('height', tube.base.height).attr('fill', `url(#${this.localId}__TubeBase)`)

      chartWrapper // Series Bottom Cap Bottom
      .append('ellipse')
      .attr('cx', tube.tickness / 2) .attr('cy', tube.height + tube.base.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeBase)`)
 

      // --------------------------- Tube Cylinder
      chartWrapper
      .append('rect') // Tube Cylinder Box
      .attr('width', tube.tickness).attr('height', tube.height).attr('fill', `url(#${this.localId}__TubeBox)`).attr('opacity', 0.7)

      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', '#E0E0DF').attr('opacity', 0.9)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', 0)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeCap)`)


      // --------------------------- Color Cylinder
      chartWrapper
      .append('path') // Cyinder Box
      .attr('d', `
      M ${0} ${TubeScale(0)}
      V ${TubeScale(0)}
      C 0 ${((tube.tickness/3/2)*1.25) + TubeScale(0) } ${tube.tickness} ${((tube.tickness/3/2)*1.25) + TubeScale(0) } ${tube.tickness} ${TubeScale(0) } 
      V ${TubeScale(0)} 
      `)
      .attr('fill', `url(#${this.localId}_${tube.color}_1)`)
      
      .attr('opacity', .9)
      .transition()
      .duration(500)
      .attr('d', () => {
      let h_ = TubeScale(columns.values.ACTUALC)
      let hy = TubeScale(0) 

      return `
      M ${0} ${hy}
      V ${TubeScale(columns.values.ACTUALC)}
      C 0 ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${h_} 
      V ${TubeScale(0)} 
      `
      })
    
      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_1)`).attr('opacity', 1)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', TubeScale(0))
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_0)`).attr('opacity', 1)
      .transition().duration(500).attr('cy', TubeScale(columns.values.ACTUALC))

      // --------------------------- Value & Grid

      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness / 2},${tube.height + tube.base.padding})`)
      .style('font-size', tube.base.tSize)
      .style('font-family', 'roboto').style('font-weight', 400)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', tube.base.tColor)
      .attr('cursor', 'default')
      .text(`${columns.values.ACTUALC}%`)

      //chartWrapper
      //.append('line')
      //.attr('x1', tube.tickness + 5).attr('y1', tube.height+0.5).attr('x2', tube.tickness + 20.5).attr('y2', tube.height)
      //.style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      //chartWrapper
      //.append('line')
      //.attr('x1', tube.tickness + 5).attr('y1', tube.height+0.5).attr('x2', tube.tickness + 20.5).attr('y2', tube.height)
      //.style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      var TubeCum = chartWrapper
      .append('g')
      .attr('transform', `translate(0,${columns.cPadding})`)

      chartWrapper
      .append('line')
      .attr('id', 'Tube_Cum').attr('x1', tube.tickness).attr('y1', TubeScale(columns.values.ACTUALC)+0.5).attr('x2', (tube.tickness*2 + columns.tPadding)/2).attr('y2', TubeScale(columns.values.ACTUALC)+0.5)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', (tube.tickness*2 + columns.tPadding)/2).attr('y1', TubeScale(columns.values.ACTUALC)+0.5).attr('x2', (tube.tickness*2 + columns.tPadding)/2).attr('y2', columns.cPadding*2)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      TubeCum
      .append('line')
      .attr('x1', (tube.tickness*2 + columns.tPadding)/2).attr('y1', columns.cPadding).attr('x2', tube.tickness + columns.tPadding-10).attr('y2', columns.cPadding)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      TubeCum
      .append('circle')
      .attr('cx', tube.tickness + columns.tPadding-10).attr('cy', columns.cPadding).attr('r',3)
      .attr('fill', '#bcbcbc')

      chartWrapper
      .append('line')
      .attr('x1', tube.tickness-5).attr('y1', TubeScale(100)-tube.tickness/3).attr('x2', tube.tickness + columns.tPadding-10).attr('y2', TubeScale(100)-tube.tickness/3)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('circle')
      .attr('cx', tube.tickness + columns.tPadding-10).attr('cy', TubeScale(100)-tube.tickness/3).attr('r',3)
      .attr('fill', '#bcbcbc')

      //chartWrapper
      //.append('line')
      //.attr('x1', tube.tickness + 15).attr('y1', 0.5).attr('x2', tube.tickness + 15.5).attr('y2', tube.height)
      //.style('stroke', '#bcbcbc').style('stroke-width', 0.5)


      // TOTAL
      chartWrapper
      .append('text')
      .attr('id', 'Tube_Total')
      .attr('transform', `translate(${tube.tickness/2},${TubeScale(100)-tube.tickness/3})`)
      .style('font-size', columns.value.tSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', columns.value.tColor)
      .attr('cursor', 'default')
      .text(Math.round((`${columns.values.ACTUAL}`)*100)/100+ '%')

      //chartWrapper
      //.append('text')
      //.attr('transform', `translate(${tube.tickness/2},${TubeScale(100)-tube.tickness/3 - columns.value.tSize[0]-2})`)
      //.style('font-size', columns.title.tSize[0])
      //.style('font-family', 'roboto')
      //.attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      //.attr('fill', columns.title.tColor[0])
      //.attr('cursor', 'default')
      //.text(columns.title.visible == 'Y' ? columns.title.desc[0] : '')

      //Culmulative
      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${columns.cPadding})`)
      .style('font-size', columns.main.tSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.main.tColor)
      .attr('cursor', 'default')
      .text('Culmulative')


      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${columns.cPadding+25})`)
      .style('font-size', columns.sub.hSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.hColor)
      .attr('cursor', 'default')
      .text('Early')

      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${columns.cPadding+42})`)
      .style('font-size', columns.sub.vSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.vColor)
      .attr('cursor', 'default')
      .text(`${columns.values.EARLYC}`+'%')

      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60},${columns.cPadding+42})`)
      .style('font-size', columns.sub.vSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.values.EARLYDC >= 0 ? '#44A9DF' : '#EC407A')
      .attr('cursor', 'default')
      .text(`${columns.values.EARLYDC}%`)

      TubeCum
      .append('path')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60+34},${columns.cPadding+42-7}) scale(0.9)`)
      .attr('d', columns.values.EARLYDC  >= 0 ? arrow.arrow.up : arrow.arrow.down)
      .attr('fill', columns.values.EARLYDC  >= 0 ? '#44A9DF' : '#EC407A')
      


      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${columns.cPadding+60})`)
      .style('font-size', columns.sub.hSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.hColor)
      .attr('cursor', 'default')
      .text('Late')

      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${columns.cPadding+77})`)
      .style('font-size', columns.sub.vSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.vColor)
      .attr('cursor', 'default')
      .text(`${columns.values.LATEC}`+'%')

      TubeCum
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60},${columns.cPadding+77})`)
      .style('font-size', columns.sub.vSize + columns.tDiff)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.values.LATEDC >= 0 ? '#44A9DF' : '#EC407A')
      .attr('cursor', 'default')
      .text(`${columns.values.LATEDC}%`)

      TubeCum
      .append('path')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60+34},${columns.cPadding+77-7}) scale(0.9)`)
      .attr('d', columns.values.LATEDC  >= 0 ? arrow.arrow.up : arrow.arrow.down)
      .attr('fill', columns.values.LATEDC  >= 0 ? '#44A9DF' : '#EC407A')

    
      // This Week
      chartWrapper
      .append('text')
      .attr('id', 'Tube_Remain')
      .attr('transform', `translate(${tube.tickness + columns.tPadding} ,${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3})`)
      .style('font-size', columns.main.tSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.main.tColor)
      .attr('cursor', 'default')
      .text(`This Week`)

      chartWrapper
      .append('text')
      .attr('id', 'Tube_Remain')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+20})`)
      .style('font-size', columns.sub.hSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.hColor)
      .attr('cursor', 'default')
      .text(`Early`)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+35})`)
      .style('font-size', columns.sub.vSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.vColor)
      .attr('cursor', 'default')
      .text(`${columns.values.EARLY}`+'%')

      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding + 60},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+35})`)
      .style('font-size', columns.sub.vSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.values.EARLYD >= 0 ? '#44A9DF' : '#EC407A')
      .attr('cursor', 'default')
      .text(`${columns.values.EARLYD}%`)

      chartWrapper
      .append('path')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60+30},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+35-6}) scale(0.9)`)
      .attr('d', columns.values.EARLYD  >= 0 ? arrow.arrow.up : arrow.arrow.down)
      .attr('fill', columns.values.EARLYD  >= 0 ? '#44A9DF' : '#EC407A')

      chartWrapper
      .append('text')
      .attr('id', 'Tube_Remain')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+50})`)
      .style('font-size', columns.sub.hSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.hColor)
      .attr('cursor', 'default')
      .text(`Late`)


      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness + columns.tPadding},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+65})`)
      .style('font-size', columns.sub.vSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.sub.vColor)
      .attr('cursor', 'default')
      .text(`${columns.values.LATE}`+'%')

      chartWrapper
      .append('text')
      .attr('transform', `translate(${tube.tickness +columns.tPadding+ 60},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+65})`)
      .style('font-size', columns.sub.vSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', columns.values.LATED >= 0 ? '#44A9DF' : '#EC407A')
      .attr('cursor', 'default')
      .text(`${columns.values.LATED}%`)

      chartWrapper
      .append('path')
      .attr('transform', `translate(${tube.tickness + columns.tPadding+60+30},${TubeScale(columns.values.ACTUALC + (100-columns.values.ACTUALC))+TubeScale(100)-tube.tickness/3+65-7}) scale(0.9)`)
      .attr('d', columns.values.LATED  >= 0 ? arrow.arrow.up : arrow.arrow.down)
      .attr('fill', columns.values.LATED  >= 0 ? '#44A9DF' : '#EC407A')
    },




    
  }
}