/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

// Dashboard

import TR_DP_MAN_OVERALL       from '../10_Trion/Dashboard/Planning/Man_Overall/index.vue'
import TR_DP_MAN_DISC          from '../10_Trion/Dashboard/Planning/Man_Disc/index.vue'

import TR_DC_STR_AREA           from '../10_Trion/Dashboard/Construction/STR_Area/index.vue'
import TR_DC_STR_STAGE          from '../10_Trion/Dashboard/Construction/STR_Stage/index.vue'

import TR_DE_SHOP_OVERALL       from '../10_Trion/Dashboard/Engineering/Shop_Overall/index.vue'
import TR_DE_SHOP_AREA          from '../10_Trion/Dashboard/Engineering/Shop_Area/index.vue'

// 3D Visualization
import TR_Erection_Network      from '../10_Trion/Visualization/Erection_Network/index.vue'
import TR_Erection_Timeline     from '../10_Trion/Visualization/Erection_Timeline/index.vue'
import TR_Block_Division        from '../10_Trion/Visualization/Block_Division/index.vue'
import TR_Layout_Equipment      from '../10_Trion/Visualization/Layout_Equipment/index.vue'
import TR_Layout_Yard           from '../10_Trion/Visualization/Layout_Yard/index.vue'

// Skyline Charts
import TR_SKYLINE_BLOCK         from '../10_Trion/Skylines/Blocks/index.vue'

// Special Charts
import TR_SCURVE_ENG            from '../10_Trion/CustomChart/SCurve_ENG/index.vue'
import TR_SCURVE_PRO            from '../10_Trion/CustomChart/SCurve_PRO/index.vue'
import TR_SCURVE_CON            from '../10_Trion/CustomChart/SCurve_CON/index.vue'

export default {
  
  // Dashboard
  TR_DP_MAN_OVERALL,
  TR_DP_MAN_DISC,
  TR_DC_STR_AREA,
  TR_DC_STR_STAGE,
  TR_DE_SHOP_OVERALL,
  TR_DE_SHOP_AREA,

  // 3D Visualization
  TR_Erection_Network,
  TR_Erection_Timeline,
  TR_Block_Division,
  TR_Layout_Equipment,
  TR_Layout_Yard,

  // Skyline Charts
  TR_SKYLINE_BLOCK,

  // Custom Charts
  TR_SCURVE_ENG,
  TR_SCURVE_PRO,
  TR_SCURVE_CON,
  

  

  
}
