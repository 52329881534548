
export default {
  data: () => ({

    chart: {
      x: 60, 
      y: 40,
      width: 580,
      height: 185,
      grid: 185/5,
      sch: 'start',
      
      start: [
        {name: 'Plan Cuml.',   code: 'PS_CUM', cType: 'LINE', lType: 'PLAN', sWidth: 0.5, sColor: '#A7A7A7', opacity: 1 },
        {name: 'Actual Cuml.', code: 'AS_CUM', cType: 'LINE', lType: 'ACT',  sWidth: 1.5, sColor: '#44A9DF', opacity: 1 },
        {name: 'Plan wk.',     code: 'PS_WK',  cType: 'BAR',  lType: 'PLAN', sWidth: 0.5, sColor: '#bcbcbc', opacity: .3},
        {name: 'Actual wk.',   code: 'AS_WK',  cType: 'BAR',  lType: 'ACT',  sWidth: 0.5, sColor: '#83D2F5', opacity: .9},
      ],

      issued: [
        {name: 'Plan Cuml.',   code: 'PF_CUM', cType: 'LINE', lType: 'PLAN', sWidth: 0.5, sColor: '#A7A7A7', opacity: 1 },
        {name: 'Actual Cuml.', code: 'AF_CUM', cType: 'LINE', lType: 'ACT',  sWidth: 1.5, sColor: '#44A9DF', opacity: 1 },
        {name: 'Plan wk.',     code: 'PF_WK',  cType: 'BAR',  lType: 'PLAN', sWidth: 0.5, sColor: '#bcbcbc', opacity: .3},
        {name: 'Actual wk.',   code: 'AF_WK',  cType: 'BAR',  lType: 'ACT',  sWidth: 0.5, sColor: '#83D2F5', opacity: .9},
      ]
    },


    schedule: [
      {id: 'start', name: 'Start - 30%',  check: true,},
      {id: 'issued', name: 'Issued - 70%', check: false,},
    ],

    // Chart List --------------------------------------------------------------------------------------------------------
    areaList: [
      {text: 'Hull',    code: 'HULL',    disc: 'OVERALL', x:5,   y:90 },
      {text: 'LQ',      code: 'LQ',      disc: 'OVERALL', x:690, y:90 },
      {text: 'Topside', code: 'TOPSIDE', disc: 'OVERALL', x:5,   y:390 },
    ],

  }),
}