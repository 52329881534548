import * as d3 from 'd3'

export default {
  methods: {

    draw_Table(data_, st) {
      
      let data = JSON.parse(JSON.stringify(data_))
      let table = [
        {title: 'Area',     name: '',       code: st.group, width: 99, tColor:'#333333', sColor: '#757575', sSize: 1, hover: ''},
        {title: 'Total',    name: '',       code: 'TOTAL',  width: 40, tColor:'#333333', sColor: '#757575', sSize: 1, hover: 'Y'},
        {title: 'Plan',     name: 'Start',  code: 'PS_CUM', width: 40, tColor:'#333333', sColor: '#bcbcbc', sSize: .5,hover: 'Y'},
        {title: '',         name: 'Issued', code: 'PF_CUM', width: 40, tColor:'',        sColor: '#bcbcbc', sSize: .5,hover: 'Y'},
        {title: 'Actual',   name: 'Start',  code: 'AS_CUM', width: 40, tColor:'#333333', sColor: '#bcbcbc', sSize: .5,hover: 'Y'},
        {title: '',         name: 'Issued', code: 'AF_CUM', width: 40, tColor:'',        sColor: '#bcbcbc', sSize: .5,hover: 'Y'},
        {title: 'Overdue',  name: 'Start',  code: 'OS',     width: 40, tColor:'#F35E90', sColor: '#F7BACF', sSize: 1, hover: 'Y'},
        {title: '',         name: 'Issued', code: 'OF',     width: 40, tColor:'',        sColor: '#F7BACF', sSize: 1, hover: 'Y'}, 
        {title: '',         name: 'Plan',   code: 'PLAN',   width: 40, tColor:'',        sColor: '#F7BACF', sSize: 1, hover: ''},
        {title: 'Progress', name: 'Actual', code: 'ACT',    width: 40, tColor:'#333333', sColor: '#F7BACF', sSize: 1, hover: ''}, 
        {title: '',         name: 'Delta',  code: 'DEV',    width: 40, tColor:'',        sColor: '#F7BACF', sSize: 1, hover: ''}, 
      ]

      let tblWidth = 500

      let x = 0
      table.forEach((d,i)=> {
        d.cX = x + (d.width / 2)
        d.sX = x
        d.eX = x  + d.width

        if(i==0) d.x = d.sX + 5
        else if (i == table.length-1) d.x =  d.eX - 15
        else if (d.code == 'ACT') d.x =  d.eX - 8
        else d.x =  d.eX - 5

        x = x + d.width
      })


      let summary = this.canvasForms
      .append('g')
      .attr('transform', `translate(${st.x +.5}, ${st.y +.5})`)

      summary
      .append('text')
      .attr('x', 0).attr('y', -7)
      .attr('font-size', 13).attr('fill', '#333')
      .text('Summary by Area')

      summary
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', tblWidth).attr('y2', 0)
      .attr('stroke', '#E0E0DF').attr('stroke-width', 1)

      summary
      .append('rect')
      .attr('x', 0).attr('y', 2)
      .attr('width', tblWidth).attr('height', 27).attr('fill', '#E0E0DF').attr('opacity', .4)

      summary
      .append('line')
      .attr('x1', 0).attr('y1', 31).attr('x2', tblWidth).attr('y2', 31)
      .attr('stroke', '#E0E0DF').attr('stroke-width', 1)



      table.forEach((d,i)=> {
        // Title
        if (d.title !== '') {
          summary
          .append('text')
          .attr('x', (i>1 && d.title !== 'Progress') ? d.cX + (d.width)/2 : d.cX)
          .attr('y', (d.name =='') ? 20 : 13)
          .attr('font-size', 11).attr('fill', d.tColor).attr('text-anchor', 'middle')
          .text(d.title)
        }
      
        summary
        .append('text')
        .attr('x', d.cX).attr('y', 25)
        .attr('font-size', 10).attr('fill', '#aaaaaa').attr('text-anchor', 'middle')
        .text(d.name)

        summary
        .append('line')
        .attr('x1', d.eX).attr('y1', 18).attr('x2', d.eX).attr('y2', 27)
        .attr('stroke', '#E0E0DF').attr('stroke-width', 1)
      })
      
      data.forEach((d,i)=> {
        d.y = 44 + (i*17)

        table.forEach((col,c) => {
          summary
          .append('text')
          .attr('id', `${st.group}_${i}_${c}`)
          .attr('transform', `translate(${col.x}, ${d.y})`)
          .attr('font-size', 10)
          .attr('fill', ()=> {
            if (c == table.length-1) return this.setDeltaColor(d[col.code])
            else if (d[col.code] == 0) return '#bcbcbc'
            else if (col.code == 'OS' || col.code == 'OF') return '#F35E90'
            else return '#757575'
          })
          .attr('text-anchor', (c==0) ? 'start' : 'end')
          .style('cursor', (col.hover == '' || d[col.code] == 0) ? 'default' : 'pointer')
          .text( () => {
            if (col.hover == '' && c > 0) return d[col.code].toFixed(1) 
            else if ((col.code == 'OS' || col.code == 'OF') && d[col.code] < 1) return ''
            else if (d[col.code] == 'OVERALL') return 'TOTAL'
            else return d[col.code]
          })
          .on('mouseover', function() {
            if(col.hover == '' || d[col.code] == 0) return
            d3.select(`#${st.group}_${i}_${c}`).transition().duration(200)
            .attr('transform', `translate(${col.x}, ${d.y-17})`)
            .attr('font-size', 11)
          })
          .on('mouseout', function() {
            if(col.hover == '' || d[col.code] == 0) return
            d3.select(this).transition().duration(200)
            .attr('transform', `translate(${col.x}, ${d.y-17})`)
            .attr('font-size', 10)
          })
          .on('click', ()=> { 
            if(col.hover == '' || d[col.code] == 0) return
            this.link_Table(d, col) 
          })

          if (c == table.length-1) {
            summary
            .append('path')
            .attr('transform', `translate(${col.eX - 12}, ${d.y-7}) scale(.6)`)
            .attr('d', this.setDeltaColor(d[col.code], 'icon'))
            .attr('fill', this.setDeltaColor(d[col.code]))
            .attr('stroke-width', .5)
          }
        })

        summary
        .append('line')
        .attr('x1', 0).attr('y1', d.y + 5).attr('x2', tblWidth).attr('y2', d.y + 5)
        .attr('stroke', (i < data.length-2)? '#E0E0DF' : '#bcbcbc').attr('stroke-width', .5)

        d.y = d.y + 17
      })

    },


    setDeltaColor(no, type) {
      if (type == null) {
        if(no == 0.0) return '#bcbcbc'
        if(no > 0) return '#44A9DF'
        if(no < 0) return '#F35E90'
      } else if (type == 'icon') {
        if(no ==0) return this.icon.zero
        if(no > 0) return this.icon.up
        if(no < 0) return this.icon.down
      }
    },
  }
}