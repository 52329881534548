import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas(selection, area_) {

      this.canvasChart = this.canvasGroup
      .append('g')
      .attr('class', `FORMS`)
      .attr('transform', `translate(${this.chart.x +.5 + area_.x}, ${this.chart.y + .5 + area_.y})`)


      this.func_barChart_Summary(
        this.canvasChart,
        this.summaryData.find(f=> f.AREA  == area_.text && f.DISC == area_.disc),
        {
          id: area_.code,
          x: 0, y: -65.5,
          title:{
            y: 3,
            main: area_.text, mainSize: 13,
            sub: 'Progress',  subSize: 9,
          },
          bar: {length: 200, height: 14, r: 0, tSize: 12, textY: 36,},
          dev: {y: 12.5, pad: 15.5, scale: .65}
        }
      ) //-----> func > barChart_Summary.js
      

      // Overall Summary
      this.func_box_Summary(
        this.canvasChart,
        this.summaryData.find(f=> f.AREA == area_.text && f.DISC == area_.disc), 
        {id: area_.code, x: 257.5, y: -48.5}
      )
,

      
      this.draw_ChartAxis(this.canvasChart) //-----> below
      this.draw_Legends(this.canvasChart) //-----> below
    },

    
    draw_ChartAxis(selection) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-27}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('No. of Block (Weekly)')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${this.chart.width + 35}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('No. of Block (Cumulative)')
    },


    draw_Legends(selection) {

      let item = selection
        .append('g')
        .attr('transform', `translate(0,-8)`)

      let x_ = 3
      this.chart.start.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 8).attr('height', 2).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNodeElValue(`#ITEM_${i}`, 'width') + 20
      })
    },
  }
}
           