import * as d3 from 'd3'
import moment from 'moment'
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({
    
    rawData:      [],
    summaryData:  [],

    canvasForms:  null,
    canvasChart:  null,

    timeline:     {},

    icon:         icon.arrow,
    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},

    schedule: [
      {name: 'Start',  check: true,},
      {name: 'Issued', check: false,},
    ]
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.summaryData = JSON.parse(JSON.stringify(this.Queries.SQL2))


      this.timeline = {
        cutoff          : sql1.CUTOFF,
        startDate       : sql1.START_DATE,
        endDate         : sql1.END_DATE,
        weekEnd         : 'friday',

        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        length          : this.chart.width,
      }

      // Filtering rawData
      this.rawData = this.rawData.filter(f=> f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate)
      this.rawData.filter(f=> f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate).forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })

      this.init_gmx_TimelineValues(this.timeline, 'day')

      this.primary.max = d3.max([
        d3.max(this.rawData, r => r.PS_WK), d3.max(this.rawData, r => r.PF_WK),
        d3.max(this.rawData, r => r.AS_WK), d3.max(this.rawData, r => r.AF_WK)
      ])
      this.primary.max = this.getMaxBound_v2(this.primary.max)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      this.secoundary.max = this.getMaxBound_v2(d3.max(this.rawData, r => r.PS_CUM))
      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
    },

    
  }
}