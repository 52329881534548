import * as d3 from 'd3'

import icon from '../../../../../../includes/primitives/icons'

export default {


  methods: {

    set_LocalValues(){

      // Get Data from data.js
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))


      // Filtering rawData
      this.rawData = this.rawData.filter(f=> f.CDATE >= sql1.START_DATE && f.CDATE <= sql1.END_DATE)
      this.rawData.forEach(d=> {
        d.year = d.CDATE.substr(0,4)
        d.month = Number(d.CDATE.substr(5,2))
      })
       
      // add properties to chart
      this.timeline.cutoff = sql1.CUTOFF
      this.timeline.start = sql1.START_DATE
      this.timeline.end = sql1.END_DATE


      
    },
  }
}