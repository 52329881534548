import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {
      
      // Canvas Group
      this.canvasGroup = this.canvasForms
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.areaList.forEach((d,i)=> {

        this.draw_ChartCanvas(this.canvasChart, d) // -----> draw_ChartCanvas.js
        
        this.primary.push({max: null, axis: null})
        this.secoundary.push({max: null, axis: null})

        // Set primary & secoundary Axis
        let chtData = this.rawData.filter(f=> f.AREA == d.code && f.DISC == d.disc)

        let maxValue = d3.max([
          d3.max(chtData, c => c.PS_WK), 
          d3.max(chtData, c => c.AS_WK),
          d3.max(chtData, c => c.PF_WK), 
          d3.max(chtData, c => c.AF_WK),
        ])
        this.primary[i].max = this.getMaxBound_v2(maxValue)
        this.primary[i].axis = d3.scaleLinear()
        .domain([0, this.primary[i].max])
        .range([0, this.chart.height])

        this.secoundary[i].max = this.getMaxBound_v2(d3.max(chtData, c => c[this.chart.start[0].code]))
        this.secoundary[i].axis = d3.scaleLinear()
        .domain([0, this.secoundary[i].max])
        .range([this.chart.height, 0])
        

        
        this.draw_Timeline() // -----> draw_Timeline.js

        this.chart_Controller(chtData, i) // -----> draw_Chart.js
      })

      // console.log(this.primary)
    },

  }
}
           