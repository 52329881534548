import * as d3 from 'd3'
import moment from 'moment'
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({

    rawData:      [],
    summaryData:  [],

    canvasForms:  null,
    canvasGroup:  null,
    canvasChart:  null,

    timeline:     {},
    current:      {sch: {},},

    icon:         icon.arrow,
    primary:      [],
    secoundary:   [],
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      // Get Data from data.js
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.summaryData = JSON.parse(JSON.stringify(this.Queries.SQL2))

      // Filtering rawData
      this.rawData = this.rawData.filter(f=> f.CDATE >= sql1.START_DATE && f.CDATE <= sql1.END_DATE)
      this.rawData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })
       
      this.current.sch = this.schedule[0]

      this.timeline = {
        cutoff   : sql1.CUTOFF,
        startDate: sql1.START_DATE,
        endDate  : sql1.END_DATE,
        length   : this.chart.width,
        weekEnd  : 'friday',
        day      : [], week: [], month: [], year: [],
        scale    : null,
      }

      this.init_gmx_TimelineValues(this.timeline, 'day')

      
    },
  }
}