import * as d3 from 'd3'

export default {
  methods: {

    func_barChart_Summary(selection, data, st) {

      let progress = selection
      .append('g')
      .attr('transform', `translate(${st.x}, ${st.y})`)

      progress
      .append('text')
      .attr('id', `TITLE_${st.id}`)
      .attr('x', 0) .attr('y', st.title.y)
      .attr('font-size', st.title.mainSize).attr('fill', '#000')
      .text(st.title.main)

      progress
      .append('text')
      .attr('x', this.getNodeElValue(`#TITLE_${st.id}`, 'width') + 3) .attr('y', st.title.y)
      .attr('font-size', st.title.subSize).attr('fill', '#858585')
      .text(st.title.sub)

      progress
      .append('text')
      .attr('id', `PLAN_TITLE_${st.id}`)
      .attr('x', 0) .attr('y', 20)
      .attr('font-size', st.bar.tSize-1).attr('fill', '#757575')
      .text('Plan')

      progress
      .append('text')
      .attr('id', `PLAN_PRO_${st.id}`)
      .attr('x', this.getNodeElValue(`#PLAN_TITLE_${st.id}`, 'width') + 5) .attr('y', 20)
      .attr('font-size', st.bar.tSize-1).attr('fill', '#333')
      .text(data.PLAN + '%')

      // Delta Value & Icon
      let margin = this.getNodeElValue(`#PLAN_TITLE_${st.id}`, 'width') + this.getNodeElValue(`#PLAN_PRO_${st.id}`, 'width')
      progress
      .append('text')
      .attr('id', `DEV_PRO__${st.id}`)
      .attr('x', margin + 15)
      .attr('y', 20).attr('font-size', st.bar.tSize-1)
      .attr('fill', this.setDeltaColor(data.DEV))
      .text(data.DEV.toFixed(1) + '%')

      progress
      .append('path')
      .attr('transform', `translate(${margin + this.getNodeElValue(`#DEV_PRO__${st.id}`, 'width') + st.dev.pad}, ${st.dev.y}) scale(${st.dev.scale})`)
      .attr('d', this.setDeltaColor(data.DEV, 'icon'))
      .attr('fill', this.setDeltaColor(data.DEV))
      .attr('stroke-width', .5)

      // Progress bar
      let barLen = (data.ACT/100) * st.bar.length

      progress
      .append('rect')
      .attr('x', 0).attr('y', 25).attr('rx', 2).attr('ry', st.bar.r)
      .attr('width', st.bar.length).attr('height', st.bar.height).attr('fill', '#F4F5F5')

      progress
      .append('rect')
      .attr('x', 0).attr('y', 25).attr('rx', 2).attr('ry', st.bar.r)
      .attr('width', barLen).attr('height', st.bar.height).attr('fill', '#83D2F5')

      // Actual Progress
      progress 
      .append('text')
      .attr('x', barLen + 2) .attr('y', st.bar.textY).attr('font-size', st.bar.tSize).attr('fill', '#000')
      .text(data.ACT + '%')

    },



  }
}